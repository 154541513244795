import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import firebase from "firebase";
import { Subscription } from "rxjs";
import { Library } from "../../interfaces/firebase-interfaces";
import { getLibraries } from "../../services/cloud-functions";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";
import { PaymentService } from "../../services/payment.service";
import { Profile } from "../profile/profile.page";

export interface StoreCollection extends Library {
  subscribed: boolean;
}

@Component({
  selector: "store",
  templateUrl: "./store.page.html",
  styleUrls: ["./store.page.scss"],
})
export class StorePage implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  subscribedSub: Subscription;

  cardContent: StoreCollection[] = [];
  filteredContent: StoreCollection[] = [];
  userContent: StoreCollection[] = [];
  filteredUserContent: StoreCollection[] = [];
  customer: Profile;

  subTabs: string[] = ["Store", "Purchased"];

  subTab: "Store" | "Purchased" = "Store";

  searchTerm: string = "";
  searching: boolean = false;

  constructor(
    private fireServ: FirebaseService,
    private payServ: PaymentService,
    private helper: HelperService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    firebase.auth().onAuthStateChanged(async (user) => {
      await this.helper.presentLoader();
      await this.getContent();
    });

    this.activeRoute.queryParams.subscribe((params) => {
      if (params["subTab"]) {
        this.subTab = params["subTab"];

        this.setContent();

        this.cdRef.detectChanges();
      } else {
        this.selectSubTab("Store");
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  async getContent() {
    this.fireServ.getUserProfileData(`stripeId`).then((customer) => {
      this.customer = customer;
    });

    return getLibraries()
      .then(async (libraries) => {
        this.cardContent = [];
        this.userContent = [];

        this.cardContent = libraries.data
          .filter((library) => library.price != 0)
          .map((library) => {
            return { ...library, subscribed: false };
          });

        this.cardContent = this.cardContent.sort((a, b) => {
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          } else return -1;
        });

        await this.getSubscribedContent();

        this.cardContent = this.cardContent
          .map((val) => {
            if (val?.chapterIdPaths?.length > 0) {
              this.userContent.push(val);
            } else {
              return val;
            }
          })
          .filter((val) => val != null);

        this.setContent();
      })
      .catch((err) => {
        console.log("Error: ", err);
      })
      .finally(async () => await this.helper.dismissLoader());
  }

  getSubscribedContent() {
    return new Promise<void>((resolve, reject) => {
      if (this.subscribedSub) {
        this.subscribedSub.unsubscribe();
      }

      this.subscribedSub = this.fireServ.getSubscribedContent().subscribe(async (libraryIds) => {
        const proms = libraryIds.map(async (id) => {
          let newId = await id;
          if (newId) {
            newId = newId.split(" ").join("_");
          }
          const libraryIndex = this.cardContent.findIndex((library) => library.id === newId);
          if (newId && libraryIndex >= 0) {
            this.cardContent[libraryIndex].subscribed = true;
          }

          return id;
        });

        await Promise.all(proms);

        resolve();
      });
    });
  }

  setContent() {
    if (this.subTab === "Store") {
      this.filteredContent = this.cardContent.filter((library) => !library.subscribed && library?.status != "admin_hold");
      this.filteredUserContent = this.userContent.filter((library) => !library.subscribed && library?.status != "admin_hold");
    } else if (this.subTab === "Purchased") {
      this.filteredContent = this.cardContent.filter((library) => library.subscribed);
      this.filteredUserContent = this.userContent.filter((library) => library.subscribed);
    }
  }

  async openCustomerPortal() {
    await this.payServ.openStripePortal(this.customer);
  }

  async handleRefresh(event) {
    await this.getContent();
    event.target.complete();
  }

  selectSubTab(subTab: any) {
    if (subTab?.detail?.value) {
      return this.router.navigate([], { queryParams: { subTab: subTab.detail.value } });
    } else {
      console.log("Sub Tab: ", subTab);
      return this.router.navigate([], { queryParams: { subTab } });
    }
  }
}
