import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { SplitPipe } from "../pipes/split.pipe";
import { AccountOptionsPopoverComponent } from "./account-options-popover/account-options-popover.component";
import { AccountOptionsComponent } from "./account-options/account-options.component";
import { BitCoverComponent } from "./bit-cover/bit-cover.component";
import { BitComponent } from "./bit/bit.component";
import { ChapterComponent } from "./chapter/chapter.component";
import { CollapsiblePartComponent } from "./collapsible-part/collapsible-part.component";
import { CollectionCoverComponent } from "./collection-cover/collection-cover.component";
import { CollectionComponent } from "./collection/collection.component";
import { EditContentDataComponent } from "./edit-content-data/edit-content-data.component";
import { HomeLogoComponent } from "./home-logo/home-logo.component";
import { MyCollectionsPopoverComponent } from "./my-collections-popover/my-collections-popover.component";
import { MyLibraryAddPopoverComponent } from "./my-library-add-popover/my-library-add-popover.component";
import { MyLibraryPopoverComponent } from "./my-library-popover/my-library-popover.component";
import { ReaderOptionsComponent } from "./reader-options/reader-options.component";
import { SearchbarComponent } from "./searchbar/searchbar.component";
import { TOSPopoverComponent } from "./tos-popover/tos-popover.component";
import { UpdateMessageComponent } from "./update-message/update-message.component";
import { UploadedBitComponent } from "./uploaded-bit/uploaded-bit.component";
import { LogoutPopoverComponent } from "./logout-popover/logout-popover.component";
import { ConfirmDeletionComponent } from "./confirm-deletion/confirm-deletion.component";
import { SelectedBitComponent } from "./selected-bit/selected-bit.component";
import { MonetizePopoverComponent } from "./monetize-popover/monetize-popover.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";

@NgModule({
  declarations: [
    CollectionComponent,
    ChapterComponent,
    SplitPipe,
    EditContentDataComponent,
    CollectionCoverComponent,
    BitCoverComponent,
    ReaderOptionsComponent,
    CollapsiblePartComponent,
    BitComponent,
    SearchbarComponent,
    AccountOptionsComponent,
    UpdateMessageComponent,
    AccountOptionsPopoverComponent,
    TOSPopoverComponent,
    MyLibraryPopoverComponent,
    MyLibraryAddPopoverComponent,
    MyCollectionsPopoverComponent,
    UploadedBitComponent,
    HomeLogoComponent,
    LogoutPopoverComponent,
    ConfirmDeletionComponent,
    SelectedBitComponent,
    MonetizePopoverComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IonicModule, DragDropModule],
  exports: [
    CollectionComponent,
    ChapterComponent,
    CollectionCoverComponent,
    BitCoverComponent,
    ReaderOptionsComponent,
    CollapsiblePartComponent,
    BitComponent,
    SearchbarComponent,
    AccountOptionsComponent,
    UpdateMessageComponent,
    MyLibraryPopoverComponent,
    MyLibraryAddPopoverComponent,
    MyCollectionsPopoverComponent,
    UploadedBitComponent,
    HomeLogoComponent,
    SelectedBitComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
