import { Component, Input, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { ModalController, PopoverController } from "@ionic/angular";
import firebase from "firebase";
import { Library } from "../../interfaces/firebase-interfaces";
import { StoreCollection } from "../../pages/store/store.page";
import { HelperService } from "../../services/helper.service";
import { MyCollectionsPopoverComponent } from "../my-collections-popover/my-collections-popover.component";
import { PaymentPopoverComponent } from "../payment-popover/payment-popover.component";

@Component({
  selector: "collection",
  templateUrl: "./collection.component.html",
  styleUrls: ["./collection.component.scss"],
})
export class CollectionComponent implements OnInit {
  @Input() collection: StoreCollection;
  @Input() store: boolean = false;
  @Input() knowbitz = false;

  guestMode: boolean = false;

  constructor(
    public router: Router,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private fireAuth: AngularFireAuth,
    private helperServ: HelperService
  ) {}

  ngOnInit() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && (user.emailVerified || user.phoneNumber)) {
        this.guestMode = false;
      } else {
        this.guestMode = true;
      }
    });
  }

  async navigateToOtherPage() {
    if (this.collection.id) {
      this.router.navigate([`${!this.store ? "libraries" : "store"}/${this.collection.id}/toc`], {
        queryParams: this.collection.volumes ? {} : { view: "Chapter" },
      });
    } else {
      console.log("No library to navigate to");
    }
  }

  async presentLibraryOptionsPopover(event: Event) {
    event.stopPropagation();
    const popover = await this.popoverCtrl.create({
      component: MyCollectionsPopoverComponent,
      componentProps: {
        libraryLink: "/libraries/" + this.collection.id + "/toc" + (this.collection.volumes ? "" : "?view=Chapter"),
        contentTitle: this.collection.title,
      },
      event,
      translucent: true,
      backdropDismiss: true,
    });
    await popover.present();
  }

  async subscribeToPopover(evnet?: Event) {
    event?.stopPropagation();

    if (this.guestMode) {
      await this.helperServ.presentToast("You're currently in guest mode. Please create an account to use this feature", true);
      return;
    }

    if (this.collection.id) {
      const popover = await this.modalCtrl.create({
        component: PaymentPopoverComponent,
        componentProps: { product: this.collection as Library, subscribed: this.collection.subscribed },
        backdropDismiss: true,
      });

      await popover.present();

      popover.onWillDismiss().then((response) => {
        if (response.data) {
          this.collection.subscribed = response.data.subscribed;
        }
      });
    }
  }
}
