import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { HelperService } from '../../services/helper.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  showPassword: boolean = false;
  passwordIcon: string = "eye";
  validation_messages = {
    password: [
      { type: "required", message: "Password is required." },
      { type: "minlength", message: "Password must be at least 6 characters long." },
    ],
    confirmPassword: [
      { type: "required", message: "Please confirm your new password." },
      { type: "notEqual", message: "Please ensure your passwords match."}
    ],
  };
  validations_form: FormGroup;


  constructor(
    private formBuilder: FormBuilder,
    private authServ: AuthService,
    private helperServ: HelperService,
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {
    this.validations_form = this.formBuilder.group({
      password: new FormControl("", Validators.compose([Validators.minLength(6), Validators.required])),
      confirmPassword: new FormControl("", Validators.required),
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
    //Change icon
    if (this.passwordIcon == "eye") {
      this.passwordIcon = "eye-off";
    } else {
      this.passwordIcon = "eye";
    }
  }
  async confirmChanges() {
    try {
      await this.authServ.updatePassword(this.validations_form.get('password').value);
      await this.helperServ.presentToast("Password updated successfully!");
      await this.popoverCtrl.dismiss();
    } catch (e) {
      console.log("Error on password change: ", e);
      await this.helperServ.presentToast("Something went wrong, please try again later.");
    }
    
  }
  
}
