import { Component, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { ActionSheetController } from "@ionic/angular";
import firebase from "firebase";
import { ConferenceData } from "../../providers/conference-data";
import { FirebaseService } from "../../services/firebase.service";
import { Profile } from "../profile/profile.page";

@Component({
  selector: "page-speaker-list",
  templateUrl: "speaker-list.html",
  styleUrls: ["./speaker-list.scss"],
})
export class SpeakerListPage implements OnInit {
  //speakers: any[] = [];
  realSpeakers: Profile[] = [];

  guestMode: boolean = false;

  constructor(
    public actionSheetCtrl: ActionSheetController,
    public confData: ConferenceData,
    public router: Router,
    public fireServ: FirebaseService
  ) {
    this.fireServ
      .getAllUserProfiles()
      .then((profiles) => {
        profiles.forEach((profile) => {
          this.realSpeakers.push({ ...profile.data() });
        });
        console.log(this.realSpeakers);
        //this.realSpeakers = profiles
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ngOnInit(): void {
    firebase.auth().onAuthStateChanged((user) => {
      console.log("Init Login page - SignedIn: ", user);
      if (user && (user.emailVerified || user.phoneNumber)) {
        this.guestMode = false;
      } else {
        this.guestMode = true;
      }
    });
  }

  ionViewDidEnter() {}

  toUserProfile() {
    this.router.navigateByUrl("profile");
  }

  toUserInfo(speaker) {
    let navigationExtras: NavigationExtras = {
      state: {
        speaker: speaker,
      },
    };
    this.router.navigateByUrl("/community/speakers/speaker-details/{{speaker.id}}", navigationExtras);
  }

  goToSpeakerTwitter(speaker: any) {}

  async openSpeakerShare(speaker: any) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: "Share " + speaker.name,
      buttons: [
        {
          text: "Copy Link",
          handler: () => {
            console.log("Copy link clicked on https://twitter.com/" + speaker.twitter);
          },
        },
        {
          text: "Share via ...",
        },
        {
          text: "Cancel",
          role: "cancel",
        },
      ],
    });

    await actionSheet.present();
  }

  async openContact(speaker: Profile) {
    const mode = "ios"; // this.config.get('mode');
    const buttons = [];
    if (speaker.publicEmail && speaker.email) {
      buttons.push({
        text: `Email ( ${speaker.email} )`,
        icon: mode !== "ios" ? "mail" : null,
        handler: () => {
          window.open("mailto:" + speaker.email);
        },
      });
    }

    if (speaker.publicPhone && speaker.phone) {
      buttons.push({
        text: `Call ( ${speaker.phone} )`,
        icon: mode !== "ios" ? "call" : null,
        handler: () => {
          window.open("tel:" + speaker.phone);
        },
      });
    }

    const actionSheet = await this.actionSheetCtrl.create({
      header: "Contact " + speaker.firstName + " " + speaker.lastName,
      buttons,
    });

    await actionSheet.present();
  }
}
