import { Injectable } from "@angular/core";
import { loadStripe } from "@stripe/stripe-js";
import firebase, { firestore, functions } from "firebase";
import { stripePublishableKey } from "../interfaces/constants";
import { Library } from "../interfaces/firebase-interfaces";
import { Profile } from "../pages/profile/profile.page";
import { createConnectedAccountDashboardLink, createConnectedAccountOnboardingLink, subscribe } from "./cloud-functions";
import { FirebaseService } from "./firebase.service";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(private fireServ: FirebaseService, private helperServ: HelperService) {}

  async initCustomer() {
    return this.fireServ.getUserProfileData("stripeId").then(async (stripeId) => {
      if (stripeId) {
        return stripeId;
      }

      const response = await functions()
        .httpsCallable("createStripeCustomer")({ user: { email: firebase.auth().currentUser.email, uid: firebase.auth().currentUser.uid } })
        .catch((err) => {
          console.error(`Error from creating stripe customer: ${err}`);
        });

      if (response) {
        return response.data.id;
      }

      return;
    });
  }

  async changeCancelAtPeriodEnd(library: Library): Promise<boolean> {
    return this.fireServ
      .getActiveSubscriptionDoc(library, ["id"])
      .then(async (doc) => {
        const response = await functions().httpsCallable("reactivateSubscription")({ subscriptionId: doc.id });
        return response.data;
      })
      .catch((err) => {
        console.error("Error reactivating: ", err);
        return false;
      });
  }

  // Checkout handler
  async subscribe(library: Library) {
    const sessionId = (
      await subscribe({
        uid: firebase.auth().currentUser.uid,
        data: {
          price: library.default_price_id,
          success_url: window.location.origin + "/success",
          cancel_url: window.location.origin + "/store",
          contentId: library.id,
          trial_period_days: library.trial_period_days || null,
          days: library.days || null,
          cycle: library.cycle || "monthly",
          authorId: library.authorId || null,
          created: firestore.Timestamp.now(),
        },
      })
    ).data;
    const stripe = await loadStripe(stripePublishableKey);
    stripe.redirectToCheckout({ sessionId });
  }

  async openStripePortal(customer: Profile) {
    await this.helperServ.presentLoader("You are being redirected. Please wait");

    if (!customer) {
      customer = await this.fireServ.getUserProfileData(`stripeId`);
    }

    const functionRef = firebase.app().functions("us-central1").httpsCallable("createPortalLink");
    const { data } = await functionRef({ stripeId: customer.uid, returnUrl: window.location.origin + "/store" });
    window.open(data.url, "_blank");
    await this.helperServ.dismissLoader();
  }

  async unsubscribe(subscriptionId: string): Promise<boolean> {
    const response = await functions().httpsCallable("deleteSubscription")({ subscriptionId: subscriptionId });

    return response.data;
  }

  async connectWithStripe(customer: Profile) {
    await this.helperServ.presentLoader("You are being redirected. Please wait");

    const { data } = await createConnectedAccountOnboardingLink({
      email: customer.email,
      stripeConnectedAccountId: customer.stripeConnectedAccountId,
    });
    window.open(data.url);
    await this.helperServ.dismissLoader();
  }

  async openConnectedAccountDashboard(customer) {
    await this.helperServ.presentLoader("You are being redirected. Please wait");
    console.log("Customer: ", customer);
    const { data } = await createConnectedAccountDashboardLink({
      stripeConnectedAccountId: customer.stripeConnectedAccountId,
    });
    window.open(data.url);
    await this.helperServ.dismissLoader();
  }
}
