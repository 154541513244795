import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, PopoverController } from "@ionic/angular";
import { AngularFireAuth } from "angularfire2/auth";
import firebase from "firebase";
import { Chapter } from "../../interfaces/firebase-interfaces";
import { HelperService } from "../../services/helper.service";
import { OptionsPopoverComponent } from "../options-popover/options-popover.component";

@Component({
  selector: "app-uploaded-bit",
  templateUrl: "./uploaded-bit.component.html",
  styleUrls: ["./uploaded-bit.component.scss"],
})
export class UploadedBitComponent implements OnInit {
  @Input() bit: Chapter & { selected?: boolean };

  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  guestMode: boolean = false;

  constructor(
    public router: Router,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private fireAuth: AngularFireAuth,
    private helperServ: HelperService
  ) {}

  ngOnInit() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && (user.emailVerified || user.phoneNumber)) {
        this.guestMode = false;
      } else {
        this.guestMode = true;
      }
    });
    console.log(this.bit);
  }

  async presentOptionsPopover(showSelect: boolean, event?: Event) {
    event.stopPropagation();
    const popover = await this.popoverCtrl.create({
      component: OptionsPopoverComponent,
      componentProps: { chapters: [this.bit], contentType: "chapter", library: this.bit.libraryId, showSelect },
      event,
      translucent: true,
      backdropDismiss: true,
    });

    popover.onWillDismiss().then((res) => {
      if (res.data && res.data.select && showSelect) {
        this.select.emit();
        this.bit.selected = true;
      }

      if (res.data && res.data.content) {
        if (res.data.content === "delete") {
          this.remove.emit();
        } else {
          this.bit = res.data.content;
        }
      }
    });
    await popover.present();
  }
}
