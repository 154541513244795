import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { PopoverController } from "@ionic/angular";
import { auth } from "firebase";
import { AuthService } from "../../services/auth.service";
import { FirebaseService } from "../../services/firebase.service";
import { HelperService } from "../../services/helper.service";
import { ChangePasswordComponent } from "../change-password/change-password.component";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  validations_form_phone: FormGroup;
  validations_form_email: FormGroup;
  captcha: auth.RecaptchaVerifier;
  confirmationResult: auth.ConfirmationResult;

  validation_messages = {
    email: [{ type: "pattern", message: "Enter a valid email." }],
    phone: [{ type: "pattern", message: "Enter a valid phone number." }],
  };

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private helperServ: HelperService,
    private fireServ: FirebaseService,
    private popoverCtrl: PopoverController
  ) {}

  ngOnInit() {
    this.validations_form_phone = this.formBuilder.group({
      phone: new FormControl("", Validators.compose([Validators.required, Validators.pattern("(\\+\\d{11})$")])),
      code: new FormControl("", Validators.required),
    });

    this.validations_form_email = this.formBuilder.group({
      email: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")])
      ),
    });

    auth().settings.appVerificationDisabledForTesting = true;

    this.captcha = new auth.RecaptchaVerifier("recaptcha-container", {
      size: "invisible",
      callback: (resp) => {
        console.log("resp: ", resp);
      },
      "expired-callback": (res) => {
        console.log("Expired: ", res);
      },
    });
  }

  async sendLink() {
    const phone = this.validations_form_phone.get("phone").value;
    if (await this.fireServ.doesPhoneExist(phone)) {
      this.confirmationResult = await this.authService.sendSMSCode(phone, this.captcha).catch((err) => {
        console.error(err);
        this.helperServ.presentToast(`Error sending code - ${err.message} Please try again.`);
        throw Error(err);
      });
      if (this.confirmationResult.verificationId) {
        this.helperServ.presentToast("Code sent successfully.");
      }
    } else {
      this.helperServ.presentToast("That phone number is not linked to an account.");
    }
  }

  async verifyCode() {
    try {
      await this.confirmationResult.confirm(this.validations_form_phone.get("code").value);
      await this.popoverCtrl.dismiss();
      const popover = await this.popoverCtrl.create({
        component: ChangePasswordComponent,
        cssClass: "resetPasswordPopover",
      });
      await popover.present();
      return true;
    } catch {
      return false;
    }
  }

  async displayToastMessage(success: boolean, value) {
    let message: string;
    if (success) {
      message = "Check your email for a link to reset your password.";
    } else {
      if (value.email == "") {
        message = 'Enter your email in the line above and then click "New Password". A link will be sent to your email.';
      } else {
        message = "Invalid email. Ensure your email is correct.";
      }
    }

    this.helperServ.presentToast(message, false, true, 5000);
    /* const toast = await this.toastCtrl.create({
      message: message,
      showCloseButton: true,
      position: 'bottom',
      closeButtonText: `Dismiss`,
      duration: 5000
    });
    await toast.present() */
  }

  forgotPassword() {
    const value = this.validations_form_email.get("email");
    this.authService.resetPasswordWithEmail(value.value).then(
      async (res) => {
        await this.displayToastMessage(true, value);
        await this.popoverCtrl.dismiss();
      },
      (err) => {
        this.displayToastMessage(false, value);
        console.log(err);
      }
    );
  }
}
