import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";
import { AngularFireAuth } from "angularfire2/auth";
import { AngularFirestore } from "angularfire2/firestore";
import firebase, { firestore } from "firebase/app";
import { BehaviorSubject, Observable, Subscription, from, of } from "rxjs";
import { app } from "../../environments/environment";
import { REVIEW_STATUS } from "../components/edit-content-data/edit-content-data.component";
import { TOSPopoverComponent } from "../components/tos-popover/tos-popover.component";
import { production } from "../interfaces/constants";
import {
  Chapter,
  ContentOptions,
  FirebaseBook,
  FirebaseChapter,
  FirebaseCollection,
  Library,
  PromoCode,
  Review,
  SupportMessage,
  UpdateDoc,
  UserBook,
  UserChapter,
  UserCollection,
  UserSubscription,
} from "../interfaces/firebase-interfaces";
import { Profile } from "../pages/profile/profile.page";
import { AuthService } from "./auth.service";
import {
  addReview,
  addToBookList,
  addToChapterList,
  addToCollectionList,
  addView,
  deleteReview,
  doesPhoneExist,
  getBackendData,
  getLatestUpdates,
  getLibraries,
  getPrevReview,
  getUserBits,
  getUserBook,
  getUserChapter,
  getUserOptions,
  getUserProfile,
  getViews,
  removeFromBookList,
  removeFromChapterList,
  removeFromCollectionList,
  saveUserContent,
  setBackendData,
  setPromoCode,
  uploadCollectionFromBits,
} from "./cloud-functions";
import { HelperService } from "./helper.service";
import { BIT_TOPICS, BIT_TYPES } from "./remote-config.service";

@Injectable({
  providedIn: "root",
})
export class FirebaseService implements OnDestroy {
  public Libraries: Library[];
  private LibrariesSub = new BehaviorSubject<Library[]>(null);
  LibrariesObs: Observable<Library[]> = this.LibrariesSub.asObservable();

  public prod: boolean = production;
  public beta: boolean =
    window.location.href.includes("myonlinelibrary") ||
    window.location.href.includes("localhost") ||
    window.location.href.includes("beta.mylibrary.world");
  public userChapters: UserChapter[] = [];
  public userBooks: UserBook[] = [];
  public userCollections: UserCollection[] = [];
  public subscribedContent: string[];
  private disabled: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    public fireAuth: AngularFireAuth,
    private router: Router,
    private helperServ: HelperService,
    private modalCtrl: PopoverController,
    private authService: AuthService,
    private afs: AngularFirestore
  ) {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        if (!(await this.getTOSState())) {
          let modal = await this.modalCtrl.create({
            component: TOSPopoverComponent,
            backdropDismiss: false,
            cssClass: "TOS-popover",
          });
          await modal.present();
          await modal.onDidDismiss().then(async (data) => {
            if (data.data.agree) {
              await this.setTOSState(true);
            } else {
              await this.authService.doLogout();
              await this.router.navigateByUrl("/login");
            }
          });
        }
      }
      if (user && (user.emailVerified || user.phoneNumber)) {
        // this.getUserChapters(); supplied by getUserBooks
        this.getUserBooks();
        this.getUserCollections();

        // this.removeWaffleItems();
        // this.copyWaffleItems();
      }
    });
  }

  canActivate() {
    console.log("Start of fireServ canActivate");
    return true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  async getAllContent(): Promise<Library[]> {
    if (!this.Libraries || this.Libraries.length === 0) {
      this.Libraries = (await getLibraries()).data;
    }
    return this.Libraries;
    // return new Promise<Library[]>(async (resolve, reject) => {
    //   getBackendData(`Collections`, [], ["data", "id"]).then(async (rawData: firestore.DocumentData[]) => {
    //     const proms = rawData.map(async (docData, i) => {
    //       const index = this.Libraries.findIndex((library) => library.id === docData.id);
    //       this.Libraries[index] = docData.data as Library;
    //       this.Libraries[index].libraryId = docData.id;
    //       this.Libraries[index].type = null;
    //       let viewCount = 0;
    //       let ratings: string[] = [];
    //       return getBackendData(`Collections/${docData.id}/Chapters`, [], ["data", "id"]).then(
    //         async (chapters: firestore.DocumentData[]) => {
    //           chapters.forEach((chap) => {
    //             if (this.Libraries[index]?.chapters) {
    //               const index2 = this.Libraries[index].chapters.findIndex((chap2) => chap2.id === chap.id);
    //               index2 !== -1
    //                 ? (this.Libraries[index].chapters[index2] = chap.data as Chapter)
    //                 : this.Libraries[index].chapters.push(chap.data as Chapter);
    //             } else {
    //               this.Libraries[index].chapters = [chap.data as Chapter];
    //             }
    //             if (chap.data.views?.length) {
    //               viewCount += chap.data.views.length;
    //             }
    //             chap.data.reviews?.forEach((review) => {
    //               ratings.push(review);
    //             });
    //           });
    //           if (this.Libraries[index].chapters?.length > 0) {
    //             this.Libraries[index].startingId = this.Libraries[index].chapters[0].id;
    //           }
    //           this.Libraries[index].avgRating = await this.getAvgReview(ratings);
    //           this.Libraries[index].views = viewCount;
    //         }
    //       );
    //     });
    //     await Promise.all(proms);
    //     this.LibrariesSub.next(this.Libraries);
    //     console.log("LIbraries: ", this.Libraries);
    //     resolve(this.Libraries);
    //   });
    // });
  }

  getSubscribedContent(): Observable<Promise<string>[] | null> {
    // Change to stripe subscriptions
    if (!this.fireAuth.auth.currentUser) {
      return of([]);
    }
    return from(
      getBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/subscriptions`, [], ["data", "id"]).then(
        (userStorage: firestore.DocumentData[]) => {
          return userStorage.map(async (element) => {
            const data = element.data as UserSubscription;
            if (data.status === "active" || data.status === "trialing" || data.status === "succeeded") {
              if (data.endAt && new Date(data.endAt.seconds * 1000) <= new Date()) {
                setBackendData(`userContent/${this.fireAuth.auth.currentUser}/subscriptions/${element.id}`, "update", {
                  status: "ended",
                  ended_at: firestore.Timestamp.now(),
                });
              } else {
                return data.contentId.split(" ").join("_");
              }
            }
          });
        }
      )
    );
  }

  async isSubscribedTo(contentId: string) {
    let subscribed = false;

    if (this.fireAuth.auth.currentUser) {
      const userSubscriptionDocs: firestore.DocumentData[] = (await getBackendData(
        `userContent/${this.fireAuth.auth.currentUser.uid}/subscriptions`,
        [],
        ["data", "id"]
      )) as firestore.DocumentData[];

      for (const subscriptionDoc of userSubscriptionDocs) {
        const subscription = subscriptionDoc.data as UserSubscription;

        if (
          (subscription.contentId === contentId || subscription.contentId === contentId.split("_").join(" ")) &&
          (subscription.status === "active" || subscription.status === "trialing" || subscription.status === "succeeded")
        ) {
          if (subscription.endAt && new Date(subscription.endAt.seconds * 1000) <= new Date()) {
            await setBackendData(`userContent/${this.fireAuth.auth.currentUser}/subscriptions/${subscriptionDoc.id}`, "update", {
              status: "ended",
              ended_at: firestore.Timestamp.now(),
            });
          } else {
            subscribed = true;
          }
        }
      }
    }

    console.log("Subscribed: ", subscribed);
    return subscribed;
  }

  subscribeTo(content: Library, documentData: any): Promise<boolean> | boolean {
    if (this.fireAuth.auth.currentUser) {
      return new Promise<any>((resolve, reject) => {
        setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/subscriptions`, "set", {
          ...documentData,
          contentId: content.id,
          status: "active",
          created: firestore.Timestamp.now(),
        })
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            console.error("subscribeTo: ", error);
            reject(false);
          });
      });
    } else {
      alert("Not logged in. Please go log in to subscribe.");
      this.router.navigate(["/login"]);
      return false;
    }
  }

  async unsubscribeTo(content: Library) {
    if (!this.disabled) {
      this.disabled = true;
      let userStorage = await getBackendData(
        `userContent/${this.fireAuth.auth.currentUser.uid}/subscriptions`,
        [
          ["contentId", "==", content.id],
          ["status", "in", ["active", "trialing", "succeeded"]],
        ],
        ["id"]
      );
      return new Promise<any>((resolve, reject) => {
        if (userStorage.length === 1) {
          setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/subscriptions/${userStorage[0].id}`, "update", {
            status: "cancelled",
            unsubscribedAt: firestore.Timestamp.now(),
          }).then((status: boolean) => {
            this.disabled = false;
            resolve(status);
          });
        } else {
          this.disabled = false;
          console.error();
        }
      });
    }
  }

  async getActiveSubscriptionDoc(content: Library, data: ("id" | "data")[]): Promise<firestore.DocumentData> {
    return new Promise<any>(async (resolve, reject) => {
      let subscriptionDocsQuery = (await getBackendData(
        `userContent/${this.fireAuth.auth.currentUser.uid}/subscriptions`,
        [
          ["contentId", "==", content.id],
          ["status", "in", ["active", "trialing", "succeeded"]],
        ],
        data
      )) as firestore.DocumentData[];
      switch (subscriptionDocsQuery.length) {
        case 0:
          console.error("No subscriptions to this library");
          reject("No subscriptions to this library");
          break;
        case 1:
          if (subscriptionDocsQuery[0].data?.created) {
            subscriptionDocsQuery[0].data.created = new Date(subscriptionDocsQuery[0].data.created.seconds * 1000);
          }
          if (subscriptionDocsQuery[0].data?.endAt) {
            subscriptionDocsQuery[0].data.endAt = new Date(subscriptionDocsQuery[0].data.endAt.seconds * 1000);
          }
          resolve(subscriptionDocsQuery[0]);
          break;
        default:
          console.error("Error: Two subscriptions to the same library");
          reject("Two subscriptions to the same library");
          break;
      }
    });
  }

  async getUserOptions(chapter: { title: string; url: string; id: string; libraryId: string }): Promise<ContentOptions | void> {
    return (await getUserOptions({ chapter: chapter, uid: this.fireAuth.auth.currentUser.uid })).data;
  }

  async updateAnnotations(chapter: { title: string; url: string; id: string; libraryId: string }, annotation, add: boolean) {
    console.log("Annotation: ", annotation);
    // `note` is a Annotation from epub.js
    const annotationType = { highlight: "highlights", underline: "notes" }[annotation.type];
    let annotationToAdd;
    if (add) {
      if (annotationType == "notes") {
        annotationToAdd = {
          cfiRange: annotation.cfiRange,
          data: annotation.data,
          type: annotation.type,
          parentText: annotation.mark.range.commonAncestorContainer.data,
          startOffset: annotation.mark.range.startOffset,
          endOffset: annotation.mark.range.endOffset,
        };
      } else if (annotationType == "highlights") {
        annotationToAdd = {
          cfiRange: annotation.cfiRange,
          type: annotation.type,
          parentText: annotation.mark.range.commonAncestorContainer.data,
          startOffset: annotation.mark.range.startOffset,
          endOffset: annotation.mark.range.endOffset,
        };
      } else {
        console.error("Unsported annotation type");
      }
    }
    getBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/${chapter.libraryId}/${chapter.id}`, [], ["data", "exists"])
      .then(async (chapterOptions: firestore.DocumentData) => {
        if (chapterOptions.exists) {
          let annotations = [];
          if (chapterOptions.data[annotationType]) {
            annotations = chapterOptions.data[annotationType] as any[];
          }
          console.log("Stored " + annotationType, annotations);
          const index = annotations.findIndex((note2) => note2.cfiRange === annotation.cfiRange);
          if (add) {
            if (index === -1) {
              annotations.push(annotationToAdd);
            } else {
              annotations[index] = annotationToAdd;
            }
          } else {
            if (index !== -1) {
              annotations.splice(index, 1);
            }
          }
          await setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/${chapter.libraryId}/${chapter.id}`, "update", {
            [annotationType]: annotations,
          });
        } else {
          if (add) {
            console.log("Chap options doesn't exist");
            await setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/${chapter.libraryId}/${chapter.id}`, "set", {
              [annotationType]: [annotationToAdd],
            });
            console.log("Done");
          } else {
            console.error("Somehow trying to remove something that doesnt exist?");
          }
        }
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  }

  async getUserChapter(chapterDocId: string, user?: string): Promise<UserChapter> {
    const book = await getUserChapter({ user: user ? user : this.fireAuth.auth.currentUser.uid, chapterDocId });
    console.log(book.data);
    return book.data;
  }

  getUserChapters(): Observable<Promise<UserChapter>[]> {
    const chapterObservable = from(
      getBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/Chapters`, [], ["data"]).then(
        (rawData: firestore.DocumentData[]) => {
          return rawData.map(async (doc) => {
            return doc.data as UserChapter;
          });
        }
      )
    );
    this.subscriptions.push(
      chapterObservable.subscribe(async (chapters) => {
        this.userChapters = await Promise.all(chapters);
      })
    );

    return chapterObservable;
  }

  getUserCollections(): Observable<Promise<UserCollection>[]> {
    const collectionsObs = from(
      getBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/Collections`, [], ["data"]).then(
        (data: firestore.DocumentData[]) => {
          return data.map(async (element) => {
            const userCollection = element.data as FirebaseCollection;
            let books = new Array<UserBook>();

            for (let bookId of userCollection.books) {
              books.push(this.userBooks.find((book) => book.id === bookId));
            }

            return {
              ...userCollection,
              books: books,
            } as UserCollection;
          });
        }
      )
    );

    this.subscriptions.push(
      collectionsObs.subscribe(async (collections) => {
        this.userCollections = await Promise.all(collections);
      })
    );

    return collectionsObs;
  }

  async getUserBook(bookId: string, user?: string): Promise<UserBook> {
    if (user) {
      const book = await getUserBook({ user: user, bookId: bookId });
      console.log(book);
      return book.data;
    } else {
      const bookDoc = (await getBackendData(
        `userContent/${this.fireAuth.auth.currentUser.uid}/Books`,
        [],
        ["data"]
      )) as firestore.DocumentData;

      var chapters = Array<UserChapter>();
      const fireBook = bookDoc.data as FirebaseBook;

      for (var chapterId of fireBook.chapters) {
        chapters.push(await this.getUserChapter(chapterId));
      }

      console.log({ ...fireBook, chapters: chapters });
      return { ...fireBook, chapters: chapters };
    }
  }

  getUserBooks(): Observable<Promise<UserBook>[]> {
    if (this.userChapters.length === 0) {
      this.getUserChapters();
    }
    const booksObservable = from(
      getBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/Books`, [], ["data"]).then((rawData: firestore.DocumentData[]) => {
        return rawData.map(async (doc) => {
          const userBook = doc.data as FirebaseBook;
          let chapters = new Array<UserChapter>();
          for (let chapterId of userBook.chapters) {
            chapters.push(this.userChapters.find((chapter) => chapter.id === chapterId)); //await this.getUserChapter(chapterId)
          }
          return { ...userBook, chapters: chapters } as UserBook;
        });
      })
    );
    this.subscriptions.push(
      booksObservable.subscribe(async (books) => {
        this.userBooks = await Promise.all(books);
      })
    );

    return booksObservable;
  }

  saveProfile(profile: Profile) {
    profile.email = this.fireAuth.auth.currentUser.email;
    profile.uid = this.fireAuth.auth.currentUser.uid;
    profile.updatedAt = firestore.Timestamp.now();

    return new Promise<any>(async (resolve, reject) => {
      if (!(await getBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}`, [], ["exists"]))) {
        profile.createdAt = profile.updatedAt;
        setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}`, "set", profile)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            console.log(error);
            reject(true);
          });
      } else {
        setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}`, "update", profile)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      }
    });
  }

  async getLatestUpdates(page: string): Promise<UpdateDoc[]> {
    return (await getLatestUpdates({ page: page })).data as UpdateDoc[];
  }

  // async updateProfile(update: any) {
  //   const userProfileDoc = this.afs.firestore.collection('userContent').doc(this.fireAuth.auth.currentUser.uid)

  //   await userProfileDoc.update(update)
  // }

  async getUserProfileData(field?: string, id: string = firebase.auth()?.currentUser?.uid): Promise<Profile> {
    if (!id) {
      return;
    }

    if (app.auth().currentUser) {
      return await new Promise<any>((resolve, reject) => {
        getBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}`, [], ["data"])
          .then(async (profile: firestore.DocumentData) => {
            if (profile.data && field) {
              profile = await getBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}`, [], [field]);
              resolve(profile[field as keyof object]);
            } else if (profile.data) {
              const data = {
                ...profile.data,
                bits: (await getUserBits({ uid: this.fireAuth.auth.currentUser.uid })).data as Chapter[],
              };
              resolve(data);
            }
          })
          .catch((error) => {
            console.log(error, "In FirebaseService");
            reject(error);
          });
      });
    } else {
      const response = await getUserProfile({ id, field });
      return response.data;
    }
  }

  getAllUserProfiles(): Promise<firestore.DocumentData[]> {
    return new Promise<any>((resolve, reject) => {
      getBackendData(`userContent`, [["public", "==", true]], ["data"])
        .then((profiles: firestore.DocumentData[]) => {
          resolve(profiles);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  }

  async initializeUser(): Promise<any> {
    const userDoc = (await getBackendData(
      `userContent/${this.fireAuth.auth.currentUser.uid}`,
      [],
      ["exists", "data"]
    )) as firestore.DocumentData;
    if (!userDoc.exists || userDoc.data.email === undefined) {
      console.log("set profile");
      setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}`, !userDoc.exists ? "set" : "update", {
        email: this.fireAuth.auth.currentUser.email,
      });
    }
  }

  async saveUserContent(userContent: {
    title: string;
    userProfile: Profile;
    status: "hidden" | "unlisted" | "public";
    cover_url: string;
    bitType: BIT_TYPES;
    topics: BIT_TOPICS;
    description: string;
    media: string[];
    resources: string[];
    links: string[];
    id?: string;
    review_status: REVIEW_STATUS;
  }): Promise<Chapter> {
    return (await saveUserContent(userContent)).data;
  }

  async deleteUserEpub(id: string) {
    if (!this.disabled) {
      this.disabled = true;
      const docData = ((await getBackendData(`Collections/${this.fireAuth.auth.currentUser.uid}`, [], ["data"])) as firestore.DocumentData)
        .data;

      await setBackendData(`Collections/${this.fireAuth.auth.currentUser.uid}/Chapters`, "delete", {});

      // If not chapters left
      if ((await getBackendData(`Collections/${this.fireAuth.auth.currentUser.uid}/chapters`, [], ["exists"])).length == 0) {
        await setBackendData(`Collections/${this.fireAuth.auth.currentUser.uid}`, "set", { ...docData, active: false });
      }
      await setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/Bits/${id}`, "delete", {});
      this.disabled = false;
    }
  }

  async getChapterData(chapterId: string, libraryId: string): Promise<FirebaseChapter> {
    const chapterDocData = (await getBackendData(`Collections/${libraryId}/Chapters/${chapterId}`, [], ["data"])) as firestore.DocumentData;
    console.log("Chapter Doc: ", chapterDocData.data, chapterId, libraryId);
    if (chapterDocData.data) {
      return chapterDocData.data as FirebaseChapter;
    }
  }

  async getPromoCode(code: string, libraryId: string): Promise<PromoCode> {
    const promoCodeData = (await getBackendData(
      `Collections/${libraryId}/subscriptionPlans`,
      [["promoCode", "==", code]],
      ["data"]
    )) as firestore.DocumentData[];

    if (promoCodeData.length !== 0) {
      if (promoCodeData.length > 1) {
        console.error("WARNING: Two promo codes with same code");
      } else {
        return promoCodeData[0].data as PromoCode;
      }
    }

    return null;
  }

  async setPromoCode(promoCode: PromoCode, libraryId: string) {
    if (!(await setPromoCode({ promoCode: promoCode, libraryId: libraryId })).data as boolean) {
      console.log("setPromoCode error");
    }
  }

  async getCustomBookTitles(): Promise<string[]> {
    return await new Promise<string[]>((resolve, reject) => {
      getBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/Books`, [], ["data"]).then((docsData: firestore.DocumentData[]) => {
        if (!(docsData.length > 0)) {
          let bookTitles = new Array<string>();
          for (let bookDoc of docsData) {
            if (bookDoc.data.title != "Favorites" || bookDoc.data.title != "Read Later") {
              bookTitles.push(bookDoc.data.title);
            }
          }
          resolve(bookTitles);
        } else {
          reject("No part titles to get");
        }
      });
    });
  }

  async removeCollection(collection: UserCollection) {
    if (!this.disabled) {
      this.disabled = true;
      if (await setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/Collections/${collection.id}`, "delete", {})) {
        await this.presentSuccessMessage(`Successfully removed your ${collection.title} collection!`);
      } else {
        console.log("error removing collection");
      }
      this.disabled = false;
    }
  }

  async changeWholeOrder(wholes: UserCollection[]) {
    wholes.forEach(async (whole, index) => {
      const newWhole: FirebaseCollection = {
        ...whole,
        books: whole.books.map((book) => book.id),
        order: index,
      };
      await setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/Collections`, "update", newWhole);
    });
  }

  async changePartOrder(parts: UserBook[]) {
    parts.forEach(async (part, index) => {
      const newPart: FirebaseBook = {
        ...part,
        chapters: part.chapters.map((chap) => chap.id),
        order: index,
      };
      await setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/Books`, "update", newPart);
    });
  }

  async changeBitOrder(bits: UserChapter[]) {
    bits.forEach(async (bit, index) => {
      const newBit = {
        ...bit,
        order: index,
      };
      await setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/Chapters`, "update", newBit);
    });
  }

  async updateCollectionList(add: boolean, books: UserBook[], collection: UserCollection, showToast = true): Promise<void> {
    if (add) {
      let status: string = (await addToCollectionList({ books: books, collection: collection, uid: this.fireAuth.auth.currentUser.uid }))
        .data;
      if (showToast) {
        switch (status.substring(0, 2)) {
          case "sa": // saved already existed
            await this.presentSuccessMessage(`Successfully added to ${collection.title}!`);
            break;
          case "sn": // saved new
            await this.presentSuccessMessage(`Successfully added ${status.substring(3)} to ${collection.title}!`);
            break;
          case "du": // duplicate
            await this.presentDuplicateMessage(collection.title);
            break;
          default: // error
            this.helperServ.presentToast("Something went wrong. Please try again.", false, true, 5000);
            console.error(status);
            break;
        }
      }
    } else {
      for (const book of books) {
        let status = (await removeFromCollectionList({ userBook: book, collection: collection, uid: this.fireAuth.auth.currentUser.uid }))
          .data;
        if (showToast) {
          switch (status.substring(0, 2)) {
            case "sr": // successfully removed
              await this.presentSuccessMessage(`Successfully removed from ${collection.title}!`);
              break;
            case "ro": // removed object
              await this.presentSuccessMessage(`Successfully removed ${status.substring(3)} from ${collection.title}!`);
              break;
            default:
              this.helperServ.presentToast("Something went wrong. Please try again.", false, true, 5000);
              console.error(status);
              break;
          }
        }
      }
    }
  }

  async updateBookList(add: boolean, chapters: (Chapter | UserChapter)[], book: UserBook, showToast = true): Promise<void> {
    if (add) {
      let status: string = (
        await addToBookList({
          chapters: chapters,
          book: book,
          uid: this.fireAuth.auth.currentUser.uid,
          chapterIds: await this.updateChaptersList(true, chapters),
        })
      ).data;
      if (showToast) {
        switch (status.substring(0, 2)) {
          case "du":
            await this.presentDuplicateMessage(book.title);
            break;
          case "su":
            await this.presentSuccessMessage(`Successfully added to ${book.title}!`);
            break;
          case "sn":
            await this.presentSuccessMessage(`Successfully added ${status.substring(2)} to ${book.title}!`);
            break;
          default:
            console.error("Error adding chapter to book", status);
            this.helperServ.presentToast("Something went wrong. Please try again.", false, true, 5000);
        }
      }
    } else if (!add) {
      for (const chapter of chapters) {
        let status: string = (await removeFromBookList({ chapter: chapter, book: book, uid: this.fireAuth.auth.currentUser.uid })).data;
        if (showToast) {
          switch (status) {
            case "sn":
              await this.presentSuccessMessage(`Successfully removed from ${book.title}!`);
              break;
            case "sr":
              await this.presentSuccessMessage(`Successfully removed ${chapter.title} from ${book.title}!`);
              break;
            default:
              console.error("Error adding chapter to book", status);
              this.helperServ.presentToast("Something went wrong. Please try again.", false, true, 5000);
          }
        }
      }
    } else {
      console.error(`Unknown case - Chapters: ${chapters} - Add: ${add}`);
    }
    this.getUserBooks();
  }

  async updateChaptersList(
    add: boolean,
    chapters: (Chapter | UserChapter)[],
    permanentlyRemove?: boolean,
    booksWithChapter?: UserBook[],
    showToast = true
  ): Promise<string[]> {
    var chapterDoc: firestore.DocumentData;
    let chapterIds: string[] = [];
    let Doc;
    for (const chapter of chapters) {
      if ((chapter as Chapter)?.number) {
        // Chapter
        Doc = (
          await getBackendData(
            `userContent/${this.fireAuth.auth.currentUser.uid}/Chapters`,
            [["originalChapterId", "==", chapter.id]],
            ["data", "path"]
          )
        )[0];
      } else if ((chapter as UserChapter)?.originalChapterId) {
        Doc = (await getBackendData(
          `userContent/${this.fireAuth.auth.currentUser.uid}/Chapters/${chapter.id}`,
          [],
          ["data", "path"]
        )) as firestore.DocumentData;
      } else {
        console.error(`Unknown case - Chapter: `, chapter, ` - Add: ${add}`);
      }

      if (add) {
        chapterIds.push(
          (await addToChapterList({ uid: this.fireAuth.auth.currentUser.uid, chapterDoc: Doc?.data, chapter: chapter })).data
        );
      } else if (!add && "custom" in chapter) {
        if (permanentlyRemove) {
          for (const book of booksWithChapter) {
            await this.updateBookList(false, [chapter], book, showToast);
          }
          await removeFromChapterList({ path: Doc.path });
          await this.presentSuccessMessage(`Successfully removed your ${chapter.title} chapter!`);
        } else {
          await this.updateBookList(false, [chapter], booksWithChapter[0], showToast);
        }
      } else {
        console.error(`Unknown case - Chapter: `, chapter, ` - Add: ${add}`);
      }
    }
    this.disabled = false;
    return chapterIds;
  }

  async presentSuccessMessage(message: string) {
    this.helperServ.presentToast(message, false, true, 5000);
  }

  async presentDuplicateMessage(list: string) {
    this.helperServ.presentToast("Already in your " + list + " list.", false, true, 5000);
  }

  async removeBook(book: UserBook, collectionsWithBook: UserCollection[]) {
    if (!this.disabled) {
      this.disabled = true;
      for (const collection of collectionsWithBook) {
        let status = (await removeFromCollectionList({ userBook: book, collection: collection, uid: this.fireAuth.auth.currentUser.uid }))
          .data;
        switch (status.substring(0, 2)) {
          case "sr": // successfully removed
            await this.presentSuccessMessage(`Successfully removed from ${collection.title}!`);
            break;
          case "ro": // removed object
            await this.presentSuccessMessage(`Successfully removed ${status.substring(3)} from ${collection.title}!`);
            break;
          default:
            this.helperServ.presentToast("Something went wrong. Please try again.", false, true, 5000);
            console.error(status);
            break;
        }
      }

      await setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}/Books/${book.id}`, "delete", {})
        .then(async () => {
          await this.presentSuccessMessage(`Successfully removed your ${book.title} book!`);
        })
        .catch((err) => {
          console.error("Error deleting your book: ", err);
        });
      this.disabled = false;
    }
  }

  async getTOSState() {
    return ((await getBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}`, [], ["data"])) as firestore.DocumentData).data.TOS;
  }

  async setTOSState(state: boolean) {
    setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}`, "update", { TOS: state });
  }

  async addView(bitId: string, libraryId: string) {
    await addView({ bitId: bitId, libraryId: libraryId, userId: this.fireAuth.auth?.currentUser?.uid });
  }

  async getViews(bitId: string, libraryId: string): Promise<number> {
    return (await getViews({ bitId: bitId, libraryId: libraryId })).data;
  }

  async addReview(bitId: string, libraryId: string, rating: number) {
    addReview({ bitId: bitId, libraryId: libraryId, rating: rating, text: "", userId: this.fireAuth.auth.currentUser.uid });
  }

  async getReview(reviewId: string): Promise<Review> {
    return ((await getBackendData(`reviews/${reviewId}`, [], ["data"])) as firestore.DocumentData).data as Review;
  }

  async getAvgReview(reviews: Array<string>): Promise<number> {
    if (reviews?.length > 0) {
      let sum = 0;
      let badIds = 0;
      const ratings: number[] = (
        await Promise.all(
          reviews.map(async (review) => {
            try {
              return (await this.getReview(review)).rating;
            } catch (e) {
              badIds++;
              console.log(`Invalid reviewId: ${review}`);
            }
          })
        )
      ).filter((r) => r);
      if (!ratings.length) {
        return 0;
      }
      return Math.round((ratings.reduce((p, c) => p + c) / ratings.length) * 10) / 10;
    }
    return 0;
  }

  async deleteReview(reviewId) {
    deleteReview({ reviewId: reviewId });
  }

  async getPrevReview(bitId): Promise<number> {
    return (await getPrevReview({ userId: this.fireAuth.auth.currentUser.uid, bitId: bitId })).data;
  }

  async sendSupportMessage(data: SupportMessage, previousPage: string): Promise<boolean> {
    data.userEmail = this.fireAuth.auth.currentUser.email;
    data.userId = this.fireAuth.auth.currentUser.uid;
    data.date = new Date();

    if (previousPage) {
      data.previousURL = previousPage;
    }
    return !(await setBackendData("supportMessages", "set", data));
  }

  async sendPrintRequest(content: any): Promise<boolean> {
    const info = {
      date: new Date(),
      userEmail: this.fireAuth.auth.currentUser.email,
      userId: this.fireAuth.auth.currentUser.uid,
      content: content,
    };
    console.log("Sending print request");

    return await setBackendData("printRequests", "set", info);
  }

  async deleteUserAccount() {
    await firebase.auth().currentUser.delete();
  }

  async updateEmail(email: string) {
    await setBackendData(`userContent/${this.fireAuth.auth.currentUser.uid}`, "update", { email: email });
  }
  async createCollectionFromBits(
    chapPaths: string[],
    description: string,
    cover_url: string,
    price: number,
    title: string,
    isSubscription: boolean,
    stripeConnectedAccountId: string
  ): Promise<{ docId: string }> {
    return (
      await uploadCollectionFromBits({
        chaps: chapPaths,
        userId: this.fireAuth.auth.currentUser.uid,
        description,
        cover_url,
        price,
        title,
        isSubscription,
        stripeConnectedAccountId,
      })
    ).data;
  }

  async doesPhoneExist(phone: string): Promise<boolean> {
    return (
      await doesPhoneExist({ phone: phone }).catch((err) => {
        this.helperServ.presentToast("Error sending code", false, true, 5000);
        throw Error(err);
      })
    ).data;
  }

  /***************
      SCRIPTS
  ***************/

  // async copyWaffleItems() {
  //   const uniqueIds = [
  //     {
  //       from: "3x3_Empathy_Factors",
  //       to: "3x3Empathy_Factors_1pg",
  //     },
  //     {
  //       from: "Art_of_Convening",
  //       to: "AoC_1pg",
  //     },
  //     {
  //       from: "Emotional_Intelligence_and_Human_Relations",
  //       to: "EQ-HR_Workshop_1pg",
  //     },
  //     {
  //       from: "Fuel_Box",
  //       to: "FuelBox_1pg",
  //     },
  //     {
  //       from: "Full_Voice",
  //       to: "FullVoice_1pg",
  //     },
  //     {
  //       from: "Graphic_Recording_and_Facilitation",
  //       to: "Graphic_1pg",
  //     },
  //     {
  //       from: "NTL_Human_Interaction_Lab",
  //       to: "NTL_1pg",
  //     },
  //     {
  //       from: "Open_Space_Technology",
  //       to: "Open_Space_1pg",
  //     },
  //     {
  //       from: "Power_of_Imagination_Studio",
  //       to: "Power_of_Imagination_1pg",
  //     },
  //     {
  //       from: "Real_Time_Strategic_Change",
  //       to: "RTSC_1pg",
  //     },
  //     {
  //       from: "T_Groups",
  //       to: "T-Groups_1pg",
  //     },
  //     {
  //       from: "Groups_for_the_Workplace",
  //       to: "T-Groups_for_Work_1pg",
  //     },
  //     {
  //       from: "Technology_of_Participation",
  //       to: "ToP_1pg",
  //     },
  //     {
  //       from: "Visual_Explorer",
  //       to: "VisualExplorer_1pg",
  //     },
  //     {
  //       from: "Whole_Scale_Change",
  //       to: "Whole_Scale_1pg",
  //     },
  //     {
  //       from: "Whole_System_Transformation",
  //       to: "Whole_System_1pg",
  //     },
  //   ];
  //   console.log("Copying waffle items");
  //   const source = this.afs.firestore.collection("Collections").doc("Collaborative Change Library").collection("Chapters");
  //   const destination = await this.afs.firestore.collection("Collections").doc("Collaborative_Change_Library").collection("Chapters").get();
  //   destination.docs.forEach(async (destinationDoc) => {
  //     const foundIndex = uniqueIds.findIndex((id) => id.from === destinationDoc.id);
  //     if (foundIndex === -1) {
  //       return;
  //     }

  //     const sourceDoc = await source.doc(destinationDoc.id.split("_").join(" ")).get();
  //     if (sourceDoc.exists) {
  //       if ((await destinationDoc.ref.collection("links").get()).empty) {
  //         destinationDoc.ref.collection("links").add({
  //           link: `https://app.mylibrary.world/r/Collaborative_Change_Library/chapter/${destinationDoc.id}`,
  //           title: "Link to full chapter in CCL",
  //         });
  //       }

  //       const links = await sourceDoc.ref.collection("links").get();
  //       if (!links.empty) {
  //         links.docs.forEach((linkDoc) => {
  //           destinationDoc.ref.collection("links").add(linkDoc.data());
  //         });
  //       }

  //       const resources = await sourceDoc.ref.collection("resources").get();
  //       if (!resources.empty) {
  //         resources.docs.forEach((resourcesDoc) => {
  //           destinationDoc.ref.collection("resources").add(resourcesDoc.data());
  //         });
  //       }

  //       const videos = await sourceDoc.ref.collection("videos").get();
  //       if (!videos.empty) {
  //         videos.docs.forEach((videosDoc) => {
  //           destinationDoc.ref.collection("videos").add(videosDoc.data());
  //         });
  //       }

  //       const onePagerDoc = await destination.docs.filter((doc) => doc.id === uniqueIds[foundIndex].to)[0].ref.get();
  //       if (onePagerDoc.exists) {
  //         if ((await onePagerDoc.ref.collection("links").get()).empty) {
  //           onePagerDoc.ref.collection("links").add({
  //             link: `https://app.mylibrary.world/r/Collaborative_Change_Library/chapter/${destinationDoc.id}`,
  //             title: "Link to full chapter in CCL",
  //           });
  //         }

  //         const links = await sourceDoc.ref.collection("links").get();
  //         if (!links.empty) {
  //           links.docs.forEach((linkDoc) => {
  //             onePagerDoc.ref.collection("links").add(linkDoc.data());
  //           });
  //         }

  //         const resources = await sourceDoc.ref.collection("resources").get();
  //         if (!resources.empty) {
  //           resources.docs.forEach((resourcesDoc) => {
  //             onePagerDoc.ref.collection("resources").add(resourcesDoc.data());
  //           });
  //         }

  //         const videos = await sourceDoc.ref.collection("videos").get();
  //         if (!videos.empty) {
  //           videos.docs.forEach((videosDoc) => {
  //             onePagerDoc.ref.collection("videos").add(videosDoc.data());
  //           });
  //         }
  //       }
  //     }
  //   });
  // }

  // async removeWaffleItems() {
  //   console.log("removing waffle items");
  //   // const source = this.afs.firestore.collection("Collections").doc("Collaborative_Change_Library").collection("Chapters");
  //   const destination = await this.afs.firestore.collection("Collections").doc("Collaborative_Change_Library").collection("Chapters").get();
  //   destination.docs.forEach(async (destinationDoc) => {
  //     if (!destinationDoc.id.includes("_1pg")) {
  //       // const sourceDoc = await source.doc(destinationDoc.id.split("_1pg").join("")).get();
  //       // if (sourceDoc.exists) {
  //       const links = await destinationDoc.ref.collection("links").get();
  //       if (!links.empty) {
  //         links.docs.forEach((linkDoc) => {
  //           const linkDocData = linkDoc.data();
  //           if (links.docs.findIndex((doc) => doc.get("title") === linkDocData.title && linkDoc.id != doc.id) !== -1) {
  //             linkDoc.ref.delete();
  //           }
  //           // destinationDoc.ref.collection("links").add(linkDoc.data());
  //         });
  //       }

  //       // destinationDoc.ref.collection("links").add({
  //       //   link: `https://app.mylibrary.world/r/Collaborative_Change_Library/chapter/${sourceDoc.id}`,
  //       //   title: "Link to full chapter in CCL",
  //       // });

  //       const resources = await destinationDoc.ref.collection("resources").get();
  //       if (!resources.empty) {
  //         resources.docs.forEach((resourcesDoc) => {
  //           const resourceDocData = resourcesDoc.data();
  //           if (resources.docs.findIndex((doc) => doc.get("title") === resourceDocData.title && resourcesDoc.id != doc.id) !== -1) {
  //             resourcesDoc.ref.delete();
  //           }
  //           // destinationDoc.ref.collection("resources").add(resourcesDoc.data());
  //         });
  //       }

  //       const videos = await destinationDoc.ref.collection("videos").get();
  //       if (!videos.empty) {
  //         videos.docs.forEach((videosDoc) => {
  //           const videosDocData = videosDoc.data();
  //           if (videos.docs.findIndex((doc) => doc.get("title") === videosDocData.title && videosDoc.id != doc.id) !== -1) {
  //             videosDoc.ref.delete();
  //           }
  //           // destinationDoc.ref.collection("videos").add(videosDoc.data());
  //         });
  //       }
  //     }
  //     // }
  //   });
  // }

  /**
   * Gets all promo codes from firebase
   * @example
   * getPromoCodes('KHPoM')
   * getPromoCodes('KHOB')
   **/
  // async getPromoCodes(collectionId: string) {
  //   const source = await this.afs.firestore.collection("Collections").doc(collectionId).collection("subscriptionPlans").get();

  //   const codes: string[] = [];

  //   source.docs.forEach((doc) => {
  //     codes.push(doc.get("promoCode"));
  //   });

  //   console.log(`${collectionId} Codes: `, codes);
  // }

  // async copyCollectionFiles(source: string, destination: string) {
  //   const sourceFolder = await firebase.storage().ref(source).listAll()
  //   // sourceFolder.items[0].
  // }

  /**
   * A script for adding promo codes to collections (free, code only access)
   * @example
   * addPromoCodes(100, 3, 'KHOB', 180, 1)
   * addPromoCodes(200, 3, 'KHPoM', 180, 1)
   * addPromoCodes(200, 4, "KHPoM", 180, 1);
   *
   **/
  async addPromoCodes(count: number, codeLength: number, collectionId: string, days: number, uses: number) {
    const source = this.afs.firestore.collection("Collections").doc(collectionId).collection("subscriptionPlans");
    const codes: string[] = [];
    for (let i = 0; i < count; i++) {
      const data = {
        availableUses: uses,
        limitUses: uses,
        days,
        free: true,
        promoCode: `${collectionId}-${this.makeString(codeLength)}`,
      };

      source.doc().set(data);
      codes.push(data.promoCode);
    }

    console.log(`${collectionId} Codes: `, codes);
  }

  makeString(length: number): string {
    let outString: string = "";
    let inOptions: string = "abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ";

    for (let i = 0; i < length; i++) {
      outString += inOptions.charAt(Math.floor(Math.random() * inOptions.length));
    }

    return outString;
  }

  // A script for replacing spaces
  // async replaceSpaces() {
  //   const userDocs = await this.afs.firestore.collection("userContent").get();
  //   userDocs.docs.forEach(async (userDoc) => {
  //     const subDocs = await userDoc.ref.collection("subscriptions").get();
  //     subDocs.docs.forEach((subDoc) => {
  //       const subDocData = subDoc.data();
  //       subDocData.contentId = subDocData.contentId.split(" ").join("_");
  //       subDoc.ref.set(subDocData);
  //     });
  //   });
  // }

  // A script for copying collections
  // async copyCollections(libraryId: string, newLibraryId: string) {
  //   const sourceLibraryDoc = await this.afs.firestore.collection("Collections").doc(libraryId).get();
  //   await this.afs.firestore
  //     .collection("Collections")
  //     .doc(newLibraryId)
  //     .set({ ...sourceLibraryDoc.data(), id: sourceLibraryDoc.get("id").split(" ").join("_") });

  //   const sourceLibraryPlans = await this.afs.firestore.collection("Collections").doc(libraryId).collection("subscriptionPlans").get();
  //   sourceLibraryPlans.forEach(async (doc) => {
  //     await this.afs.firestore
  //       .collection("Collections")
  //       .doc(newLibraryId)
  //       .collection("subscriptionPlans")
  //       .doc(doc.id)
  //       .set({ ...doc.data() });
  //   });

  //   const sourceDocs = await this.afs.firestore.collection("Collections").doc(libraryId).collection("Chapters").get(); // Source

  //   sourceDocs.forEach(async (sourceDoc) => {
  //     await this.copyDoc(newLibraryId, sourceDoc, sourceDoc.id.split(" ").join("_"));
  //   });
  // }

  // async copyDoc(newLibraryId: string, sourceDoc: firestore.QueryDocumentSnapshot<firestore.DocumentData>, newDocId: string) {
  //   const destinationDoc = this.afs.firestore.collection("Collections").doc(newLibraryId).collection("Chapters").doc(newDocId);
  //   await destinationDoc.set({ ...sourceDoc.data(), id: sourceDoc.get("id").split(" ").join("_") }); // Destination doc set
  //   const chapterSubCollections = ["links", "videos", "resources"];
  //   chapterSubCollections.forEach(async (collectionId) => {
  //     console.log(collectionId);
  //     const sourceSubCollections = await sourceDoc.ref.collection(collectionId).get();
  //     console.log(sourceSubCollections);
  //     sourceSubCollections.docs.forEach((subDoc) => {
  //       console.log(subDoc);
  //       destinationDoc.collection(collectionId).doc(subDoc.id.split(" ").join("_")).set(subDoc.data());
  //     });
  //   });
  // }

  // async copyStorageFolder(libraryId: string) {
  //   const ref = app.storage().ref().child(libraryId);
  //   const files = await ref.listAll();

  //   files.items.forEach(async (item) => {
  //     const download = await item.getDownloadURL();
  //     const xhr = new XMLHttpRequest();
  //     xhr.responseType = "blob";
  //     xhr.onload = (event) => {
  //       const blob = xhr.response;
  //       app.storage().ref().child(ref.name.split(" ").join("_")).child(item.name.split(" ").join("_")).put(blob);
  //       console.log(blob, event);
  //     };
  //     xhr.open("GET", download);
  //     xhr.send();
  //   });

  //   files.prefixes.forEach(async (prefix) => {
  //     const nestedItems = await prefix.listAll();
  //     nestedItems.items.forEach(async (nestedItem) => {
  //       const download = await nestedItem.getDownloadURL();
  //       const xhr = new XMLHttpRequest();
  //       xhr.responseType = "blob";
  //       xhr.onload = (event) => {
  //         const blob = xhr.response;
  //         app.storage().ref().child(ref.name.split(" ").join("_")).child(prefix.name).child(nestedItem.name.split(" ").join("_")).put(blob);
  //         console.log(blob, event);
  //       };
  //       xhr.open("GET", download);
  //       xhr.send();
  //     });
  //   });
  // }

  // async addPublicStatus() {
  //   (await app.firestore().collection("Collections").get()).forEach(async (col) => {
  //     (await col.ref.collection("Chapters").get()).forEach((chap) => {
  //       chap.ref.update({ status: "public" });
  //     });
  //   });
  // }

  // async addTypesToBits() {
  // const belongingChaps = await app.firestore().collection("Collections").doc("Belonging").collection("Chapters").get();
  // belongingChaps.docs.forEach((doc) => {
  //   doc.ref.update({ bitType: "other" });
  // });
  // const covidChaps = await app.firestore().collection("Collections").doc("Covid_Opportunity").collection("Chapters").get();
  // covidChaps.docs.forEach((doc) => {
  //   doc.ref.update({ bitType: "essay" });
  // });
  // const peterChaps = await app.firestore().collection("Collections").doc("Vaill").collection("Chapters").get();
  // peterChaps.docs.forEach((doc) => {
  //   doc.ref.update({ bitType: "essay" });
  // });
  // const peterChaps = await app.firestore().collection("Collections").doc("eXtension_eFieldbooks").collection("Chapters").get();
  // peterChaps.docs.forEach((doc) => {
  //   doc.ref.update({ bitType: "other" });
  // });
  // }

  // async addSnapshotsToKnowbits() {
  //   const cclChaps = await app.firestore().collection("Collections").doc("Collaborative_Change_Library").collection("Chapters").get();
  //   const cclSnapChapsRef = app.firestore().collection("Collections").doc("CCL_Snapshots").collection("Chapters");
  //   cclChaps.docs.forEach(async (doc) => {
  //     if (doc.id.includes("1pg")) {
  //       await cclSnapChapsRef.doc(doc.id).set({ ...doc.data() });
  //     }
  //   });
  // }
}
