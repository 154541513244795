import { Injectable } from "@angular/core";
import firebase, { storage } from "firebase";
import { REVIEW_STATUS } from "../components/edit-content-data/edit-content-data.component";
import { Chapter } from "../interfaces/firebase-interfaces";
import { Profile } from "../pages/profile/profile.page";
import { AuthService } from "./auth.service";
import { FirebaseService } from "./firebase.service";
import { BIT_TOPICS, BIT_TYPES } from "./remote-config.service";
@Injectable({
  providedIn: "root",
})
export class StorageService {
  storageRef: firebase.storage.Reference;
  user: firebase.User;

  constructor(private authServ: AuthService, private fireServ: FirebaseService) {
    this.storageRef = storage().ref();
    firebase.auth().onAuthStateChanged((user) => {
      if (user && (user.emailVerified || user.phoneNumber)) {
        this.user = user;
      } else {
        this.user = null;
      }
    });
  }

  async setProfilePhoto(photoFile: File): Promise<string> {
    const userStorage = this.storageRef.child("Images").child(this.user.uid);
    var metadata = { name: "Profile-photo_" + photoFile.name };

    const file = userStorage.child(metadata.name);

    const fileUpload = file.put(photoFile);

    return await fileUpload.then(
      async (snap) => {
        return await snap.ref.getDownloadURL();
      },
      (err) => {
        console.error("Error uploading File", err);
        return "";
      }
    );
  }

  async saveCoverPhoto(cover_file: File): Promise<string> {
    const userStorage = this.storageRef.child(this.user.uid);
    var metadata = { name: "Profile-photo_" + cover_file.name };

    const file = userStorage.child(metadata.name);

    const fileUpload = file.put(cover_file);

    return await fileUpload.then(
      async (snap) => {
        return await snap.ref.getDownloadURL();
      },
      (err) => {
        console.error("Error uploading File", err);
        return "";
      }
    );
  }

  async uploadEpub(
    epubFile,
    contentData: {
      userProfile: Profile;
      title: string;
      status: "hidden" | "unlisted" | "public";
      cover_url: string;
      bitType: BIT_TYPES;
      topics: BIT_TOPICS;
      description: string;
      media: string[];
      resources: string[];
      links: string[];
      review_status: REVIEW_STATUS;
    }
  ): Promise<Chapter> {
    if (!this.user) {
      return;
    }

    return await this.fireServ.saveUserContent(contentData).then(async (res) => {
      const userStorage = this.storageRef.child(this.user.uid);
      var metadata = { name: epubFile.name };

      const file = userStorage.child(res.id + ".epub");

      const fileUpload = file.put(epubFile, { customMetadata: metadata });

      await fileUpload.then(
        async (snap) => {},
        (err) => {
          console.error("Error uploading File", err);
          return "";
        }
      );

      return res;
    });
  }

  async deleteEpub(id: string) {
    return await this.fireServ.deleteUserEpub(id).then(async (res) => {
      const userStorage = this.storageRef.child(this.user.uid);

      const file = userStorage.child(id + ".epub");

      await file.delete();

      return true;
    });
  }
}
